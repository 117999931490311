import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const EditCronJob = (props) => {
  const [open, setOpen] = useState(false);
  const [cronJob, setCronJob] = useState({idJob: 0, keyWord: '', description: '',minute: '', hour: '', dayOfMonth: '', month: '', dayOfWeek: '', script: '',errors: {
    description: '',
    keyWord: '',
    minute: '',
    hour: '',
    dayOfMonth: '',
    month: '',
    dayOfWeek: '',
    script: ''
}});

  const handleClickOpen = () => {
    setCronJob({idJob: props.cronJob.idJob, keyWord: props.cronJob.keyWord, description: props.cronJob.description ,minute: props.cronJob.minute, hour: props.cronJob.hour, dayOfMonth: props.cronJob.dayOfMonth,
      month: props.cronJob.month, dayOfWeek: props.cronJob.dayOfWeek, script: props.cronJob.script, errors: {
        description: '',
        keyWord: '',
        minute: '',
        hour: '',
        dayOfMonth: '',
        month: '',
        dayOfWeek: '',
        script: ''
    }})
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setCronJob({...cronJob, [event.target.name]: event.target.value});
    validateField(event.target.name,event.target.value);
  }
  const validateField = (field,fieldValue) => {
    switch(field) {
         case 'description': {//ok
         //statements;
              console.log("Validating"+field)
              var pattern = /\w/
              console.log(pattern.test(fieldValue));
              if(pattern.test(fieldValue)){
                   cronJob.errors.description="";
                   console.log(cronJob.errors.description);
              }else{
                   cronJob.errors.description="Formato incorrecto para description";
                   console.log(cronJob.errors.description);
              }
              
              break;
         }
         case 'keyWord': {//ok
              //statements;
              console.log("Validating"+field);
              var pattern= /(^@(annually|yearly|monthly|weekly|daily|hourly|reboot)$)|^(_)$/
              if(pattern.test(fieldValue)){
                   cronJob.errors.keyWord="";
                   console.log(cronJob.errors.keyWord);
              }else{
                   cronJob.errors.keyWord="Formato incorrecto para Keyword";
                   console.log(cronJob.errors.keyWord);
              }
              break;
         }
         case 'minute': {//ok
         //statements;
              console.log("Validating"+field)
              if(fieldValue!=='*'){
                   var pattern =/(^\d{1,2}$)|(^((\d){1,2},)+(\d){1,2}$)|(^((\d){1,2}(\/|-)+(\d){1,2}$))|(\*)/i
                   if(pattern.test(fieldValue)){
                        cronJob.errors.minute="";
                        console.log(cronJob.errors.minute);
                   }else{
                        cronJob.errors.minute="Formato incorrecto para Minute";
                        console.log(cronJob.errors.minute);
                   }
              }else{
                   cronJob.errors.minute="";
                   console.log(cronJob.errors.minute);
              }
              break;
         }
         case 'hour': {//ok
              //statements;
              console.log("Validating"+field)
              if(fieldValue!=='*'){
                   var pattern = /(^\d{1,2}$)|(^((\d){1,2},)+(\d){1,2}$)|(^((\d){1,2}(\/|-)+(\d){1,2}$))|\*/i
                   if(pattern.test(fieldValue)){
                        cronJob.errors.hour="";
                        console.log(cronJob.errors.hour);
                   }else{
                        cronJob.errors.hour="Formato incorrecto para Hour";
                        console.log(cronJob.errors.hour);
                   }
              }else{
                    cronJob.errors.hour="";
                   console.log(cronJob.errors.hour);
              }
              break;
         }
         case 'dayOfMonth': {//ok
              //statements;
              console.log("Validating"+field);
              if(fieldValue!=='*'){
                   var pattern = /(^\d{1,2}$)|(^((\d){1,2},)+(\d){1,2}$)|(^((\d){1,2}(\/|-)+(\d){1,2}$))|\*/i
                   if(pattern.test(fieldValue)){
                        cronJob.errors.dayOfMonth="";
                        console.log(cronJob.errors.dayOfMonth);
                   }else{
                        cronJob.errors.dayOfMonth="Formato incorrecto para DayOfMonth";
                        console.log(cronJob.errors.dayOfMonth);
                   }
              }else{
                    cronJob.errors.dayOfMonth="";
                   console.log(cronJob.errors.dayOfMonth);
              }
              break;
         }
         case 'month': {
              //statements;
              console.log("Validating"+field)
              console.log("Validating"+field);
              if(fieldValue!=='*'){
                   var pattern = /(^\d{1,2}$)|(^((\d){1,2},)+(\d){1,2}$)|(^((\d){1,2}(\/|-)+(\d){1,2}$))|\*|(^(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)$)|\*/i
                   if(pattern.test(fieldValue)){
                        cronJob.errors.month="";
                        console.log(cronJob.errors.month);
                   }else{
                        cronJob.errors.month="Formato incorrecto para Month";
                        console.log(cronJob.errors.month);
                   }
              }else{
                    cronJob.errors.month="";
                   console.log(cronJob.errors.month);
              }
              break;
         }
         case 'dayOfWeek': {
              //statements;
              console.log("Validating"+field);
              if(fieldValue!=='*'){
                   var pattern = /(^[0-7]$)|(^([0-7],)+[0-7]$)|(^([0-7](\/|-)+[0-7]$))|(sun|mon|tue|wed|thu|fri|sat)|\*/i
                   if(pattern.test(fieldValue)){
                        cronJob.errors.dayOfWeek=""
                        console.log(cronJob.errors.dayOfWeek);
                   }else{
                        cronJob.errors.dayOfWeek="Formato incorrecto para DayOfWeek";
                        console.log(cronJob.errors.dayOfWeek);
                   }
              }else{
                    cronJob.errors.dayOfWeek="";
                   console.log(cronJob.errors.dayOfWeek);
              }
              break;
         }
         case 'script': {
              //statements;
              cronJob.errors.dayOfWeek="";
              console.log("Validating"+field);
              console.log("Is a valid script");
              break;
         }
         default: {
         //statements;
         break;
         }
    }
    if(cronJob.errors.description==='' && cronJob.errors.keyWord==='' && cronJob.errors.minute==='' && cronJob.errors.hour==='' && cronJob.errors.dayOfMonth==='' &&
               cronJob.errors.month==='' &&  cronJob.errors.dayOfWeek==='' && cronJob.errors.script===''){
          cronJob.errors.existError=false;
     }else{
          cronJob.errors.existError=true;
     }
}    

  // Update car and close modal form
const handleSave = () => {
    props.updateCronJob(cronJob, props.link);
    handleClose();
   }

   return (
    <div>
      <Button color="primary" size="small" onClick={handleClickOpen}>Edit</Button>
      <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Cron job</DialogTitle>
          <DialogContent>
               <TextField autoFocus fullWidth label="Description" name="description" 
              value={cronJob.description} onChange={handleChange}/> <br/>
               {cronJob.errors.description!=='' && 
                <span className='error'>{cronJob.errors.description}</span>}<br/>
               <TextField autoFocus fullWidth label="Keyword" name="keyWord" 
              value={cronJob.keyWord} onChange={handleChange}/> <br/>
               {cronJob.errors.keyWord!=='' && 
                <span className='error'>{cronJob.errors.keyWord}</span>}<br/>
               <TextField autoFocus fullWidth label="Minute" name="minute" 
              value={cronJob.minute} onChange={handleChange}/> <br/>
               {cronJob.errors.minute!=='' && 
                <span className='error'>{cronJob.errors.minute}</span>}<br/> 
               <TextField autoFocus fullWidth label="Hour" name="hour" 
              value={cronJob.hour} onChange={handleChange}/> <br/>
               {cronJob.errors.hour!=='' && 
                <span className='error'>{cronJob.errors.hour}</span>}<br/>
               <TextField autoFocus fullWidth label="Day of Month" name="dayOfMonth" 
              value={cronJob.dayOfMonth} onChange={handleChange}/> <br/>
               {cronJob.errors.dayOfMonth!=='' && 
                <span className='error'>{cronJob.errors.dayOfMonth}</span>}<br/>
               <TextField autoFocus fullWidth label="Month" name="month" 
              value={cronJob.month} onChange={handleChange}/> <br/>
               {cronJob.errors.month!=='' && 
                <span className='error'>{cronJob.errors.month}</span>}<br/>
                <TextField autoFocus fullWidth label="Day of Week" name="dayOfWeek" 
              value={cronJob.dayOfWeek} onChange={handleChange}/> <br/>
               {cronJob.errors.dayOfWeek!=='' && 
                <span className='error'>{cronJob.errors.dayOfWeek}</span>}<br/>
               <TextField autoFocus fullWidth label="Script" name="script" 
              value={cronJob.script} onChange={handleChange}/> <br/>
               {cronJob.errors.script!=='' && 
                <span className='error'>{cronJob.errors.script}</span>}<br/>
            </DialogContent>
          <DialogActions>
              <Button color="secondary" onClick={handleClose}>Cancelar</Button>
              <Button color="primary" onClick={handleSave} disabled={cronJob.errors.existError}>Guardar</Button>
           </DialogActions>
       </Dialog> 
   </div>
  );
};

export default EditCronJob;
