import React, { Component } from 'react';
import ReactTable from "react-table-6";
import 'react-table-6/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddUser from './AddUser';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import {SERVER_URL} from '../constants.js';
import Grid from '@material-ui/core/Grid';

class UserList extends Component {
    
    render() {    
      const columns = [{
        Header: 'User ID',
        accessor: 'id'
      },
      {
        Header: 'User Name',
        accessor: 'username'
      },
      {
        Header: 'Password',
        accessor: 'password',
        show: false
      }, 
      {
        Header: 'Role',
        accessor: 'role',
        
      }, 
      {
        sortable: false,
        filterable: false,
        width: 100, 
        accessor: '_links.self.href',
        Cell: ({value, row}) => (<EditUser user={row} link={value} updateUser={this.updateUser} 
                  fetchUsers={this.fetchUsers} />),
      },{
        sortable: false,
        filterable: false,
        width: 100, 
        accessor: '_links.self.href',
        Cell: ({value, row}) => (<DeleteUser user={row} link={value} deleteUser={this.deleteUser} 
                  fetchUsers={this.fetchUsers} />),
      }]
      
      return (
        <div className="App" >
          <Grid item xs={3}>
            <AddUser addUser={this.addUser} fetchUsers={this.fetchUsers} />
          </Grid>
          <ReactTable data={this.state.users} columns={columns} 
             filterable={true} pageSizeOptions={[5,10,15,20]} defaultPageSize={5} />
          <ToastContainer autoClose={1500} /> 
        </div>
      );
      }
  constructor(props) {
    super(props);
    this.state = { cronJobs: [] };
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    this.fetchUsers();

  }

  fetchUsers = () => {
    const token = sessionStorage.getItem("jwt");
    fetch(SERVER_URL + 'users',
      {
        headers: {'Authorization': token}
      }
    )
    .then((response) => response.json()) 
    .then((responseData) => { 
      this.setState({ 
        users: responseData,
      }); 
    })
    .catch(err => console.error(err)); 
  }

  updateUser(user, link) {
    console.log("Editing User");
    const token = sessionStorage.getItem("jwt");
    fetch(SERVER_URL +'editUser', 
    { method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(user)
    })
    .then(res => {
      toast.success("Usuario guardado exitosamente", {
        position: toast.POSITION.TOP_RIGHT
      });
      this.fetchUsers();
    })
    .catch(err => 
      toast.error("Error guardando usuario", {
        position: toast.POSITION.TOP_RIGHT
      }) 
    )
  }

  addUser(user) {
    console.log("Adding User");
    const token = sessionStorage.getItem("jwt");
    fetch(SERVER_URL +'addUser', 
    { method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(user)
    })
    .then(res => {
      toast.success("Usuario guardado exitosamente", {
        position: toast.POSITION.TOP_RIGHT
      });
      this.fetchUsers();
    })
    .catch(err => 
      toast.error("Error guardando usuario", {
        position: toast.POSITION.TOP_RIGHT
      }) 
    )
  }


  deleteUser(user, link) {
    const token = sessionStorage.getItem("jwt");
    fetch(SERVER_URL +'deleteUser', 
    { method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(user)
    })
    .then(res => {
      toast.success("Usuario eliminado exitosamente", {
        position: toast.POSITION.TOP_RIGHT
      });
      this.fetchUsers();
    })
    .catch(err => 
      toast.error("Error eliminando usuario", {
        position: toast.POSITION.TOP_RIGHT
      }) 
    )
  }
  
}

export default UserList;
