import React, { useState } from "react";
import ReactDOM from 'react-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MainPanel from './MainPanel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {SERVER_URL} from '../constants.js'
import DialogTitle from '@material-ui/core/DialogTitle';


const Login = () => {
    const [user, setUser] = useState({username: '', password: '', role: ''});
    const [isAuthenticated, setAuth] = useState(false);
    const [role, setRole] = useState('');

    const handleChange = (event) => {
        setUser({...user, [event.target.name] : event.target.value})
      }

    const login = () => {
        fetch(SERVER_URL + 'login', {
          method: 'POST',
	  body: JSON.stringify(user)
        })
        .then(res => {
          const jwtToken = res.headers.get('Authorization');
          if (jwtToken !== null) {
            sessionStorage.setItem("jwt", jwtToken);
            setAuth(true);
          }else {
            toast.warn("Check your username and password", {
              position: toast.POSITION.TOP_RIGHT
            }) 
          }
        })
        .catch(err => console.error(err))
      }


    const getUser = () => {
        const token = sessionStorage.getItem("jwt");
        console.log("token=="+token);
        fetch(SERVER_URL +'getUser', 
        { method: 'POST', 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token
          },
          body: JSON.stringify(user)
        })
        .then((response) => response.json()) 
        .then((responseData) => { 
          sessionStorage.setItem("username", responseData.username);
          sessionStorage.setItem("role", responseData.role);
          setRole(responseData.role);
        })
        .catch(err => console.error(err)); 
      }

    if (isAuthenticated === true) {
        getUser();
        return <MainPanel/>
      }
      else {
        return (
          <div align="center">
            <DialogTitle>Entrar a CronManager</DialogTitle>
            <TextField name="username" 
              label="Username" onChange={handleChange} /><br/> 
            <TextField type="password" name="password" 
              label="Password" onChange={handleChange} /><br/><br/> 
            <Button variant="contained" color="primary" 
              onClick={login}>
              Entrar
            </Button>
            <ToastContainer autoClose={1500} /> 
          </div>
        );
      }
}

export default Login;
