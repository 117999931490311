import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const DeleteUser = (props) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({id: 0, username: '', password: '',role: ''});

  const handleClickOpen = () => {
    setUser({id: props.user.id, username: props.user.username, password: props.user.password ,role: props.user.role })
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setUser({...user, [event.target.name]: event.target.value});
  }

  // Update car and close modal form
const handleSave = () => {
    props.deleteUser(user, props.link);
     handleClose();
   }

   return (
    <div>
      <Button color="primary" size="small" onClick={handleClickOpen}>Delete</Button>
      <Dialog open={open} onClose={handleClose}>
          <DialogTitle>¿Seguro que desea borrar el usuario seleccionado?</DialogTitle>
          <DialogContent>
            <input type="text" placeholder="User ID" name="id" 
              value={user.id} onChange={handleChange}/><br/>
            <input type="text" placeholder="User Name" name="username" 
              value={user.username} onChange={handleChange}/><br/>  
          </DialogContent>
          <DialogActions>
              <Button color="secondary" onClick={handleClose}>Cancelar</Button>
              <Button color="primary" onClick={handleSave}>Guardar</Button>
           </DialogActions>
       </Dialog> 
   </div>
  );
};

export default DeleteUser;
