import React, { useState } from 'react';
import './App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
//import CronJobList from './components/CronJobList';
import Login from './components/Login';



function App() {
  const [isAuthenticated, setAuth] = useState(false);
  const logout = () => {
    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("username");
    sessionStorage.removeItem("role");
    setAuth(false);
    window.location.href = '/'
  }

  return (
  
    <div className="App">
      <AppBar position="relative" color="primary">
        <Toolbar>
          <Typography variant="h5" color="inherit">
            Cron Manager
          </Typography>
        </Toolbar>
      </AppBar>
      <div align="right">
        <Button variant="text" color="secondary" onClick={logout}>
                Logout
          </Button>
      </div>
      <Login />
    </div>
    
  );
  
}

export default App;
