import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const EditUser = (props) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({id: 0, username: '', role: '',errors: {
    id: '',
    username: '',
    role: '',
    password: ''
}});

  const handleClickOpen = () => {
    setUser({id: props.user.id, username: props.user.username, password: props.user.password ,role: props.user.role, errors: {
        id: '',
        username: '',
        role: '',
        password: ''
    }})
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    setUser({...user, [event.target.name]: event.target.value});
  }   

  // Update car and close modal form
const handleSave = () => {
    props.updateUser(user, props.link);
    console.log(user);
    handleClose();
   }

   return (
    <div>
      <Button color="primary" size="small" onClick={handleClickOpen}>Edit</Button>
      <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Modificar usuario</DialogTitle>
          <DialogContent>
               <TextField autoFocus fullWidth label="User Name" name="username" 
              value={user.username} onChange={handleChange}/> <br/>
               {user.errors.username!=='' && 
                <span className='error'>{user.errors.username}</span>}<br/>
              <Select
                name="role"
                id="role"
                value={user.role}
                onChange={handleChange}
              >
                <MenuItem value={"ADMIN"}>Admin</MenuItem>
                <MenuItem value={"USER"}>User</MenuItem>
              </Select> 
            </DialogContent>
          <DialogActions>
              <Button color="secondary" onClick={handleClose}>Cancelar</Button>
              <Button color="primary" onClick={handleSave}>Guardar</Button>
           </DialogActions>
       </Dialog> 
   </div>
  );
};

export default EditUser;
