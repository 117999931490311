import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const EditCronJob = (props) => {
  const [open, setOpen] = useState(false);
  const [cronJob, setCronJob] = useState({idJob: 0, keyWord: '', description: '',minute: '', hour: '', dayOfMonth: '', month: '', dayOfWeek: '', script: ''});

  const handleClickOpen = () => {
    setCronJob({idJob: props.cronJob.idJob, keyWord: props.cronJob.keyWord, description: props.cronJob.description ,minute: props.cronJob.minute, hour: props.cronJob.hour, dayOfMonth: props.cronJob.dayOfMonth,
      month: props.cronJob.month, dayOfWeek: props.cronJob.dayOfWeek, script: props.cronJob.script })
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setCronJob({...cronJob, [event.target.name]: event.target.value});
  }

  // Update car and close modal form
const handleSave = () => {
    props.deleteCronJob(cronJob, props.link);
     handleClose();
   }

   return (
    <div>
      <Button color="primary" size="small" onClick={handleClickOpen}>Delete</Button>
      <Dialog open={open} onClose={handleClose}>
          <DialogTitle>¿Seguro que desea borrar este job?</DialogTitle>
          <DialogContent>
            <input type="text" placeholder="JOB ID" name="idJob" 
              value={cronJob.idJob} onChange={handleChange}/><br/>
            <input type="text" placeholder="Description" name="description" 
              value={cronJob.description} onChange={handleChange}/><br/>  
          </DialogContent>
          <DialogActions>
              <Button color="secondary" onClick={handleClose}>Cancelar</Button>
              <Button color="primary" onClick={handleSave}>Borrar</Button>
           </DialogActions>
       </Dialog> 
   </div>
  );
};

export default EditCronJob;
