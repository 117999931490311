import React, { Component } from 'react';
import ReactTable from "react-table-6";
import 'react-table-6/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddCronJob from './AddCronJob';
import EditCronJob from './EditCronJob';
import DeleteCronJob from './DeleteCronJob';
import Button from '@material-ui/core/Button';
import {SERVER_URL} from '../constants.js';
import Grid from '@material-ui/core/Grid';

class CronJobList extends Component {

    render() {    
      const columns = [{
        Header: 'Job ID',
        accessor: 'idJob'
      },
      {
        Header: 'Description',
        accessor: 'description'
      },
      {
        Header: 'Keyword',
        accessor: 'keyWord'
      },
      {
        Header: 'Minute',
        accessor: 'minute'
      }, {
        Header: 'Hour',
        accessor: 'hour',
      }, {
        Header: 'Day of Month',
        accessor: 'dayOfMonth',
      }, {
        Header: 'Month',
        accessor: 'month',
      }, {
        Header: 'Day of Week',
        accessor: 'dayOfWeek',
      }, {
        Header: 'Script',
        accessor: 'script',
      }, {
        sortable: false,
        filterable: false,
        width: 100, 
        accessor: '_links.self.href',
        Cell: ({value, row}) => (<EditCronJob cronJob={row} link={value} updateCronJob={this.updateCronJob} 
                  fetchCronJobs={this.fetchCronJobs} />),
      },{
        sortable: false,
        filterable: false,
        width: 100, 
        accessor: '_links.self.href',
        Cell: ({value, row}) => (<DeleteCronJob cronJob={row} link={value} deleteCronJob={this.deleteCronJob} 
                  fetchCronJobs={this.fetchCronJobs} />),
      }]
      
      return (
        <div className="App" >
          <AddCronJob addCronJob={this.addCronJob} fetchCronJobs={this.fetchCronJobs} />
          <ReactTable data={this.state.cronJobs} columns={columns} 
             filterable={true} pageSizeOptions={[10,15,20,25]} defaultPageSize={10} />
            <Grid item xs={3}>
              <Button variant="contained" color="primary" style={{margin: 10}} onClick={this.handleCreateBackup}>
                Generate Backup
              </Button>
              <Button variant="contained" color="primary" style={{margin: 10}} onClick={this.handleWriteCronTab}>
                Confirm changes
              </Button>
            </Grid>
            
          <ToastContainer autoClose={1500} /> 
        </div>
      );
      }
  constructor(props) {
    super(props);
    this.state = { cronJobs: [] };
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    this.fetchCronJobs();
  }

  fetchCronJobs = () => {
    const token = sessionStorage.getItem("jwt");
    fetch(SERVER_URL + 'cronJobs',
      {
        headers: {'Authorization': token}
      }
    )
    .then((response) => response.json()) 
    .then((responseData) => { 
      this.setState({ 
        cronJobs: responseData,
      }); 
    })
    .catch(err => console.error(err)); 
  }

  updateCronJob(cronJob, link) {
    console.log("Editing Cron Job");
    const token = sessionStorage.getItem("jwt");
    fetch(SERVER_URL +'editCronJob', 
    { method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(cronJob)
    })
    .then(res => {
      toast.success("Cambios guardados exitosamente", {
        position: toast.POSITION.TOP_RIGHT
      });
      this.fetchCronJobs();
    })
    .catch(err => 
      toast.error("Error guardando los cambios", {
        position: toast.POSITION.TOP_RIGHT
      }) 
    )
  }


  deleteCronJob(cronJob, link) {
    const token = sessionStorage.getItem("jwt");
    fetch(SERVER_URL +'deleteCronJob', 
    { method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(cronJob)
    })
    .then(res => {
      toast.success("Trabajo eliminado exitosamente", {
        position: toast.POSITION.TOP_RIGHT
      });
      this.fetchCronJobs();
    })
    .catch(err => 
      toast.error("Error eliminando trabajo", {
        position: toast.POSITION.TOP_RIGHT
      }) 
    )
  }

  addCronJob(cronJob) {
    const token = sessionStorage.getItem("jwt");
    fetch(SERVER_URL +'addCronJob', 
      { method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify(cronJob)
      })
      .then(res => {
        toast.success("Job guardado exitosamente", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.fetchCronJobs();
      })
      .catch(err => 
        toast.error("Error guardando job", {
          position: toast.POSITION.TOP_RIGHT
        }) 
      )
  }

  handleCreateBackup = () => {
    console.log("Creating backup...");
    const token = sessionStorage.getItem("jwt");
    fetch(SERVER_URL +'createCronTabBackup',
      {
        headers: {'Authorization': token}
      }
    )
    .then((response) => {
    	toast.success("Respaldo creado exitosamente", {
          position: toast.POSITION.TOP_RIGHT
        });
    })
    .catch(err => console.error(err)); 
    this.fetchCronJobs();
   };

   handleWriteCronTab = () => {
    console.log("Writing crontab...");
    const token = sessionStorage.getItem("jwt");
    fetch(SERVER_URL +'writeCronTab',
      {
        headers: {'Authorization': token}
      }
    )
    .then((response) => {
    	toast.success("Crontab actualizado exitosamente", {
          position: toast.POSITION.TOP_RIGHT
        });
    })
    .catch(err => console.error(err)); 
    this.fetchCronJobs();
   };
}

export default CronJobList;
