import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import UserList from "./UserList";
import CronJobList from "./CronJobList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          <span>{children}</span>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function MainPanel() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [role, setRole] = React.useState(
    String(sessionStorage.getItem("role"))
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log("Changing tab");
  };
  
  console.log("Role en panel"+sessionStorage.getItem("role"));
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Trabajos crontab" {...a11yProps(0)} />
          {sessionStorage.getItem("role")==='ADMIN' && 
            <Tab label="Gestion Usuarios" {...a11yProps(1)} />}
          
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <CronJobList/>
      </TabPanel>
      {sessionStorage.getItem("role")==='ADMIN' && 
            <TabPanel value={value} index={1}>
            <UserList/>
          </TabPanel>}
      
    </div>
  );
}
