import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const AddUser = (props) => {
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState({id: 0,username: '',password: '', role: '',errors: {
               username: '',
               password: '',
               role: ''
          }});
    
    // Open the modal form
    const handleClickOpen = () => {
     setOpen(true);
    };
    
    // Close the modal form
    const handleClose = () => {
     setOpen(false);
    };
    
    const handleChange = (event) => {
          setUser({...user, [event.target.name]: event.target.value});
    }

   // Save car and close modal form
   const handleSave = () => {
          props.addUser(user);
          handleClose();
    }

    return (
        <div>
          <Button variant="contained" color="primary" style={{margin: 10}} onClick={handleClickOpen}>
            New User
          </Button>
          <Dialog open={open} onClose={handleClose}>
             <DialogTitle>Nuevo Usuario</DialogTitle>
             <DialogContent>
               <TextField autoFocus fullWidth label="User Name" name="username" 
              value={user.username} onChange={handleChange}/> <br/>
               {user.errors.username!=='' && 
                <span className='error'>{user.errors.username}</span>}<br/>
               <TextField type="password" autoFocus fullWidth label="Password" name="password" 
              value={user.password} onChange={handleChange}/> <br/>
               {user.errors.password!=='' && 
                <span className='error'>{user.errors.password}</span>}<br/> 
               <Select
                name="role"
                id="role"
                value={user.role}
                onChange={handleChange}
              >
                <MenuItem value={"ADMIN"}>Admin</MenuItem>
                <MenuItem value={"USER"}>User</MenuItem>
              </Select> 
               </DialogContent>
             <DialogActions>
                <Button color="secondary" onClick={handleClose}>Cancelar</Button>
                <Button color="primary" onClick={handleSave}>Guardar</Button>
             </DialogActions>
           </Dialog> 
        </div>
      );


};

export default AddUser;
